import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { User as FireUser } from 'firebase';
import fire from '../config/fire';

type User = {
  current: FireUser | null,
  update: number
}

type Auth = {
  user: User,
  update: () => void
}

export const AuthContext = React.createContext<Auth>({user: {current: null, update: 0}, update: () => {}});

type Props = {
  children?: ReactNode
}

export const AuthProvider: FunctionComponent<Props> = ({ children }) => {
  const [user, setUser] = useState<User>({current: null, update: 0});

  const update = () => {
    setUser({ ...user, update: user.update + 1 });
  };

  useEffect(() => {
    fire.auth().onAuthStateChanged(currentUser => {
      setUser(user => {
        return { ...user, current: currentUser }
      })
    });
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        update
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}