import React, { FunctionComponent, ReactNode } from 'react';
import SEO from '../components/SEO';

type Props = {
  children?: ReactNode
}

const PageTemplate: FunctionComponent<Props> = ({ children }) => {
  return (
    <React.Fragment>
      <SEO/>
      {children}
    </React.Fragment>
  )
}

export default PageTemplate;