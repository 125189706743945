import React, { useCallback, useContext } from 'react';
import { AuthContext } from '../auth/Auth';
import PageTemplate from './PageTemplate';
import FontWeight from '../components/FontWeight';

const Settings = () => {
  const currentUser = useContext(AuthContext).user.current;
  const { update } = useContext(AuthContext);

  const handleProfileUpdate = useCallback(
    async event => {
      event.preventDefault();
      const { name } = event.target.elements;

      currentUser?.updateProfile({displayName: name.value})
        .then(() => {
          update();
        })
    },
    [currentUser, update]
  );

  return (
    <PageTemplate>
      <h2>Edit your account</h2>
      <p>Need to update your personal details?</p>
      <form onSubmit={handleProfileUpdate}>
        <label>Name</label>{' '}
        <input name="name" type="name" placeholder={currentUser?.displayName || 'Name'} /><br />
        <button type="submit">Save</button>
      </form>
      <p>{currentUser?.emailVerified ? <React.Fragment>Well done, your email is <FontWeight weight={'semiBold'}>verified</FontWeight>!</React.Fragment> :
        <React.Fragment>Oh no, you have <FontWeight weight={'semiBold'}>not</FontWeight> yet verified your email!</React.Fragment>}</p>
      <p>Coming next: update email, update password</p>
    </PageTemplate>
  )
}

export default Settings;