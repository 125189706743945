import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children?: ReactNode,
  justify?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
}

const StyledRow = styled.div`
	display: flex;
	flex: 0 1 auto;
	flex-direction: row;
	flex-wrap: wrap;
	
  justify-content: ${(p: Props) => p.justify === 'start' && 'flex-start'};
  justify-content: ${(p: Props) => p.justify === 'end' && 'flex-end'};
  justify-content: ${(p: Props) => p.justify === 'center' && 'center'};
  justify-content: ${(p: Props) => p.justify === 'between' && 'space-between'};
  justify-content: ${(p: Props) => p.justify === 'around' && 'space-around'};
  justify-content: ${(p: Props) => p.justify === 'evenly' && 'space-evenly'};

`;

const Row: FunctionComponent<Props> = ({ children, justify }) => {
  return (
    <StyledRow justify={justify}>
      {children}
    </StyledRow>
  )
}

export default Row;