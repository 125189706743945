import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

type fontWeights = 'thin'|'extraLight'|'light'|'regular'|'medium'|'semiBold'|'bold'|'extraBold'|'black'|100|200|300|400|500|600|700|800|900;

type Props = {
  children?: ReactNode,
  weight: fontWeights
}

const StyledFontWeight = styled.b`
  font-weight: ${(p: Props) => p.weight};
`;

const FontWeight: FunctionComponent<Props> = ({ children, weight }) => {
  let weightProp = 400 as fontWeights;

  switch (weight) {
    case 'thin' || 100: weightProp = 100; break;
    case 'extraLight' || 200: weightProp = 200; break;
    case 'light' || 300: weightProp = 300; break;
    case 'regular' || 400: weightProp = 400; break;
    case 'medium' || 500: weightProp = 500; break;
    case 'semiBold' || 600: weightProp = 600; break;
    case 'bold' || 700: weightProp = 700; break;
    case 'extraBold' || 800: weightProp = 800; break;
    case 'black' || 900: weightProp = 900; break;
  }

  return (
    <StyledFontWeight weight={weightProp}>
      {children}
    </StyledFontWeight>
  )
}

export default FontWeight;