import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './fonts/fonts.css';
import { GlobalStyle } from './style/GlobalStyle';
import { AuthProvider } from './auth/Auth';
import LoggedInRoute from './auth/LoggedInRoute';
import Container from './components/Container';
import Col from './components/Col';
import Row from './components/Row';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import HotCocoaReviews from './pages/HotCocoaReviews';
import LogInOrRegister from './pages/LogInOrRegister';
import Secret from './pages/Secret';
import Settings from './pages/Settings';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <GlobalStyle />
        <Container>
          <Row justify="between">
            <Col expanse={6} md={7} xl={8}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/hot-cocoa-reviews" component={HotCocoaReviews} />
                <Route path="/login" component={LogInOrRegister} />
                <LoggedInRoute path="/secret" component={Secret} />
                <LoggedInRoute path="/settings" component={Settings} />
                <Route component={NotFound} />
              </Switch>
            </Col>
            <Col expanse={6} md={5} xl={4}>
              <Navigation />
            </Col>
          </Row>
          <Footer />
        </Container>
      </Router>
    </AuthProvider>
  );
}

export default App;
