import React from 'react';
import PageTemplate from './PageTemplate';

const Secret = () => {
  return (
    <PageTemplate>
      <h2>Secret stuff</h2>
      <p>This is <i>very</i> secret.</p>
    </PageTemplate>
  )
}

export default Secret;