import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../style/breakpoints';

type Props = {
  children?: ReactNode
}

const StyledContainer = styled.div`
  margin: 1rem auto 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (min-width: ${breakpoints.sm}px) {
    padding-left: 3rem;
    padding-right: 3rem;
  } 
	max-width: 100rem;
`;

const Container: FunctionComponent<Props> = ({ children }) => {
  return (
    <StyledContainer>
      {children}
    </StyledContainer>
  )
}

export default Container;