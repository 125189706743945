import React, { useContext } from 'react';
import { AuthContext } from '../auth/Auth';
import PageTemplate from './PageTemplate';

const Home = () => {
  const currentUser = useContext(AuthContext).user.current;

  return (
    <PageTemplate>
      <h2>Hello {currentUser?.displayName || 'stranger'}</h2>
      <p>First, let me introduce myself... My name is Fleur and I love hot cocoas <i>(understatement of the year?)</i>, or as some people like to call them, hot chocolates. These days I’m trying to stick to a reasonable one a day, although it’s sometimes two.</p>
      <p>For some reason, I am faintly obsessed with creating a <i>(...I guess this?)</i> website that looks like it was made in the year 2000.</p>
      <p>Since I did not fancy using HTML tables for the layout, I’ve made my own version of <i>Rows</i> and <i>Columns</i> with React and styled-components.</p>
      <p>But I digress.</p>
      <p>As you can see, I even included a picture of a vintage ‘Hot Chocolate’ ad. And in case you were wondering, yes, I own the rights to use this picture on my website. Thirty-five pounds well spent.</p>
    </PageTemplate>
  )
}

export default Home;