import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import fire from '../config/fire';
import { AuthContext } from '../auth/Auth';
import Col from './Col';
import Row from './Row';
import FontWeight from './FontWeight';
import { breakpoints } from '../style/breakpoints';

import small from '../images/cadburys-drinking-chocolate-1x.jpg';
import large from '../images/cadburys-drinking-chocolate-2x.jpg';

const Image = styled.img`
  height: 100%;
  width: 100%;
`;

const Menu = styled.div`
  text-align: right;
`

const Links = styled.ul`
  padding-left: 0;
  list-style-type: none;
  
  li a {
    &:before,
    &:after {
      color: #1A1A1A;
    }
  
    &:before {
      content: "[ ";
    }
    
    &:after {
      content: " ]";
    }
    
    &.active {
      &:before {
        content: "*[ ";
      }
    }
  }
`

const Link = styled(NavLink)`
  &.active {
    &:before {
      color: #1A1A1A;
      content: "*";
    }
  }
`

const Navigation = () => {
  const currentUser = useContext(AuthContext).user.current;

  const handleLogOut = useCallback(
    async event => {
      event.preventDefault();
      await fire
        .auth()
        .signOut()
        .catch(error => {
          alert(error);
        });
    },
    []
  );

  return (
    <React.Fragment>
      <Row justify="end">
        <Col expanse={11} lg={6}>
          <Menu>
            <Links>
              <li><NavLink exact to="/">home</NavLink></li>
              <li><NavLink to="/hot-cocoa-reviews">hot cocoa reviews</NavLink></li>
              <li><NavLink to="/secret">secret</NavLink></li>
            </Links>
            {currentUser ? <React.Fragment>
              logged in as: <span style={{
                wordBreak: 'break-all'
              }}><FontWeight weight={'semiBold'}>{currentUser?.displayName || 'user'}</FontWeight></span><br /><br />
              [ <Link to="/settings">settings</Link> | <Link as="a" href="#logout" onClick={handleLogOut}>log out</Link> ]<br /><br />
            </React.Fragment> :
            <React.Fragment>
              Click <NavLink to="/login">here</NavLink> to log in<br /><br />
            </React.Fragment>}
          </Menu>
        </Col>
        <Col expanse={11} sm={9} md={8} lg={6}>
          <Image src={small} srcSet={`
            ${small} ${breakpoints.sm}w,
            ${large} ${breakpoints.xl}w`}
            alt="1959 British advertisement for Cadbury's Drinking Chocolate" />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Navigation;