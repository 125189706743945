import React from 'react';
import PageTemplate from './PageTemplate';
import FontWeight from '../components/FontWeight';

const HotCocoaReviews = () => {
  return (
    <PageTemplate>
      <h2>The best hot cocoa brands, ever</h2>
      <p>In no particular order.<br /><br />
        Currently in review: Cadbury Hot Chocolate Cocoa Powder, Green & Blacks Organic Hot Chocolate<br /><br />
        x = 123 − 45 − 67 + 89</p>
      <p><h3>Twinings Swiss Chocolate Drink</h3>
        <FontWeight weight={'medium'}>Taste:</FontWeight> 100<sup>3</sup><br />
        <FontWeight weight={'medium'}>Packaging:</FontWeight> 5/6<br />
        <FontWeight weight={'medium'}>Thought it said ‘Twinnings’:</FontWeight> 999/998<br />
        <FontWeight weight={'medium'}>Smoothness:</FontWeight> smooth<br />
        <FontWeight weight={'medium'}>Mixability (with milk, including oat/soy):</FontWeight> 10/10<br /><br />
        Overall, I give it a 96 out of x.</p>

      <p><h3>Cadbury Hot Chocolate Cocoa Powder</h3>
        <FontWeight weight={'medium'}>Taste:</FontWeight> 101/212<br />
        <FontWeight weight={'medium'}>Packaging:</FontWeight> 2/purple<br />
        <FontWeight weight={'medium'}>Too sweet?:</FontWeight> yes<br />
        <FontWeight weight={'medium'}>Smoothness:</FontWeight> 5<br />
        <FontWeight weight={'medium'}>Mixability (with milk, including oat/soy):</FontWeight> 7/10<br /><br />
        Overall, I give it an 88 out of x.</p>

      <p><h3>Green & Blacks Organic Hot Chocolate</h3>
        Coming soon...</p>
    </PageTemplate>
  )
}

export default HotCocoaReviews;