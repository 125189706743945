import React, {useCallback, useContext} from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../auth/Auth';
import fire from '../config/fire';
import PageTemplate from './PageTemplate';
import FontWeight from '../components/FontWeight';

const LogInOrRegister = () => {
  const currentUser = useContext(AuthContext).user.current;

  const redirectToHome = () => {
    return (
      <Redirect to="/" />
    )
  }

  const handleLogin = useCallback(
    async event => {
      event.preventDefault();
      const { email, password } = event.target.elements;
      await fire
        .auth()
        .signInWithEmailAndPassword(email.value, password.value)
        .then(() => {
          return redirectToHome();
        })
        .catch(error => {
          alert(error);
        });
    },
    []
  );

  const handleSignUp = useCallback(
    async event => {
      event.preventDefault();
      const { name, email, password } = event.target.elements;

      await fire
        .auth()
        .createUserWithEmailAndPassword(email.value, password.value)
        .then(async response => {
          const user = response.user;

          fire.auth().signOut();

          await user?.updateProfile({displayName: name.value})
            .then(() => {
              fire.auth().signInWithEmailAndPassword(email.value, password.value);
              return redirectToHome();
            })
            .catch(error => {
              alert(error);
            })

          user?.sendEmailVerification()
            .catch(error => {
              alert(error)
            });
        })
        .catch(error => {
          alert(error);
        })
    },
    []
  )

  return (
    currentUser ? redirectToHome() : <PageTemplate>
      <h2>But, why?</h2>
      <p>Log in:</p>
      <form onSubmit={handleLogin}>
        <label>Email</label>{' '}
        <input name="email" type="email" placeholder="Email" /><br />
        <label>Password</label>{' '}
        <input name="password" type="password" placeholder="Password" /><br />
        <button type="submit">Log in</button>
      </form><br />
      <p>...New here?<br /><br />
        You can now create a <FontWeight weight={'semiBold'}>free</FontWeight> account!<br /><br />
        Register:</p>
      <form onSubmit={handleSignUp}>
        <label>Name</label>{' '}
        <input name="name" type="name" placeholder="Name" /><br />
        <label>Email</label>{' '}
        <input name="email" type="email" placeholder="Email" /><br />
        <label>Password</label>{' '}
        <input name="password" type="password" placeholder="Password" /><br />
        <button type="submit">Sign up</button>
      </form><br />
    </PageTemplate>
  )
}

export default LogInOrRegister;