import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'

const SEO = () => {
  let path = useLocation().pathname.substr(1);

  return (
    <Helmet>
      <link rel="canonical" href={`https://cupofhotcocoa.com/${path}`} />
    </Helmet>
  );
}

export default SEO;