import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    font-family: Gelasio, Times, "Times New Roman", serif;
    font-size: 15px;
    color: #1A1A1A;
    text-size-adjust: 100%;
  }
  
  body {
    margin: 0;
    padding: 0;
  }
  
  div {
    box-sizing: border-box;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: Raleway, Arial, "Helvetica Neue", Helvetica, sans-serif;
  }
`;