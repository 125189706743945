import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { breakpoints } from '../style/breakpoints';

type expanse = 1|2|3|4|5|6|7|8|9|10|11|12;

type expansePercentage = {
  xs?: number,
  sm?: number
  md?: number,
  lg?: number,
  xl?: number
}

type Props = {
  children?: ReactNode,
  expanse?: expanse,
  sm?: expanse,
  md?: expanse,
  lg?: expanse,
  xl?: expanse,
  narrow?: boolean
}

interface StyledProps extends Props {
  expansePercentage: expansePercentage
}

const StyledCol = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1 1 ${(p: StyledProps) => p.narrow ? 'content' : 'auto'};
	&:not(:first-child) {
	  padding-left: .5rem;
	}
	&:not(:last-child) {
	  padding-right: .5rem;
	}
	
  flex-basis: ${(p: StyledProps) => p.expansePercentage.xs ? `${p.expansePercentage.xs}%` : p.narrow ? 'content' : 'auto'};
  max-width: ${(p: StyledProps) => p.expansePercentage.xs && `${p.expansePercentage.xs}%`};

  @media (min-width: ${breakpoints.sm}px) {
    flex-basis: ${(p: StyledProps) => p.expansePercentage.sm ? `${p.expansePercentage.sm}%` : p.narrow ? 'content' : 'auto'};
    max-width: ${(p: StyledProps) => p.expansePercentage.sm && `${p.expansePercentage.sm}%`};
  }
  
  @media (min-width: ${breakpoints.md}px) {
    flex-basis: ${(p: StyledProps) => p.expansePercentage.md ? `${p.expansePercentage.md}%` : p.narrow ? 'content' : 'auto'};
    max-width: ${(p: StyledProps) => p.expansePercentage.md && `${p.expansePercentage.md}%`};
  }
  
  @media (min-width: ${breakpoints.lg}px) {
    flex-basis: ${(p: StyledProps) => p.expansePercentage.lg ? `${p.expansePercentage.lg}%` : p.narrow ? 'content' : 'auto'};
    max-width: ${(p: StyledProps) => p.expansePercentage.lg && `${p.expansePercentage.lg}%`};
  }
  
  @media (min-width: ${breakpoints.xl}px) {
    flex-basis: ${(p: StyledProps) => p.expansePercentage.xl ? `${p.expansePercentage.xl}%` : p.narrow ? 'content' : 'auto'};
    max-width: ${(p: StyledProps) => p.expansePercentage.xl && `${p.expansePercentage.xl}%`};
  }
`;

const updateExpansePercentage = (size: string, expanse: expanse) => {
  let expansePercentage;
  switch (expanse) {
    case 1: expansePercentage = {[size]: 8.33333333333}; break;
    case 2: expansePercentage = {[size]: 16.6666666667}; break;
    case 3: expansePercentage = {[size]: 25}; break;
    case 4: expansePercentage = {[size]: 33.3333333333}; break;
    case 5: expansePercentage = {[size]: 41.6666666667}; break;
    case 6: expansePercentage = {[size]: 50}; break;
    case 7: expansePercentage = {[size]: 58.3333333333}; break;
    case 8: expansePercentage = {[size]: 66.6666666667}; break;
    case 9: expansePercentage = {[size]: 75}; break;
    case 10: expansePercentage = {[size]: 83.3333333333}; break;
    case 11: expansePercentage = {[size]: 91.6666666667}; break;
    case 12: expansePercentage = {[size]: 100}; break;
  }

  return expansePercentage;
}

const Col: FunctionComponent<Props> = ({ children, expanse, sm, md, lg, xl, narrow }) => {
  let expansePercentage = {};

  expansePercentage = {...expansePercentage, ...expanse && updateExpansePercentage('xs', expanse)};
  expansePercentage = {...expansePercentage, ...sm && updateExpansePercentage('sm', sm)};
  expansePercentage = {...expansePercentage, ...md && updateExpansePercentage('md', md)};
  expansePercentage = {...expansePercentage, ...lg && updateExpansePercentage('lg', lg)};
  expansePercentage = {...expansePercentage, ...xl && updateExpansePercentage('xl', xl)};

  return (
    <StyledCol expansePercentage={expansePercentage || {}} narrow={narrow}>
      {children}
    </StyledCol>
  )
}

export default Col;