import React, { FunctionComponent, useContext } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { AuthContext } from './Auth';

interface PrivateRouteProps {
  component: React.ComponentType<RouteProps> | React.ComponentType<any>,
  path?: string
}

const LoggedInRoute: FunctionComponent<PrivateRouteProps> = ({ component, ...rest}) => {
  const currentUser = useContext(AuthContext).user.current;

  return (
    <Route
      {...rest}
      render={routeProps =>
        currentUser ? (
          React.createElement(component, {...routeProps})
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
}

export default LoggedInRoute;