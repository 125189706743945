import React from 'react';
import styled from 'styled-components';
import FontWeight from './FontWeight';

const StyledFooter = styled.div`
	font-style: italic;
`;

const Footer = () => {
  return (
    <StyledFooter>
      <hr />
      <p><FontWeight weight={'medium'}>Cup of Hot Cocoa</FontWeight>, est. 2020 / Fleur Schepen / <a href="https://fleur.dev" target="_blank" rel="noopener noreferrer">fleur.dev</a></p>
    </StyledFooter>
  )
}

export default Footer;